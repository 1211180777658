/* === Roboto - 300 */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url([object Module]);
	src: local("Roboto Light"),
		local("Roboto-Light"),
		url([object Module]) format("embedded-opentype"),
		url([object Module]) format("woff2"),
		url([object Module]) format("woff"),
		url([object Module]) format("truetype"),
		url([object Module]) format("svg");
	font-display: swap;
}

/* === Roboto - 400 */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url([object Module]);
	src: local("Roboto Regular"),
		local("Roboto-Regular"),
		url([object Module]) format("embedded-opentype"),
		url([object Module]) format("woff2"),
		url([object Module]) format("woff"),
		url([object Module]) format("truetype"),
		url([object Module]) format("svg");
	font-display: swap;
}

/* === Roboto - 500 */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url([object Module]);
	src: local("Roboto Medium"),
		local("Roboto-Medium"),
		url([object Module]) format("embedded-opentype"),
		url([object Module]) format("woff2"),
		url([object Module]) format("woff"),
		url([object Module]) format("truetype"),
		url([object Module]) format("svg");
	font-display: swap;
}

/* === Roboto - 700 */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url([object Module]);
	src: local("Roboto Bold"),
		local("Roboto-Bold"),
		url([object Module]) format("embedded-opentype"),
		url([object Module]) format("woff2"),
		url([object Module]) format("woff"),
		url([object Module]) format("truetype"),
		url([object Module]) format("svg");
	font-display: swap;
}
